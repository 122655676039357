import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import ButtonSolid from "../Button/ButtonSolid";

const products = [
  {
    slug: "/fiberglass-trench-covers/",
    label: "Fiberglass Trench Covers",
  },
];
const ProductsSidebar = ({ className }) => {
  let slug = "";
  if (typeof window !== "undefined") {
    slug = window.location.pathname;
  }

  return (
    <div className="sticky top-40 bg-gray-50 px-10 py-10">
      <div className="heading-five">Products</div>
      <ul className="space-y-2">
        {products.map((product, index) => {
          if (product.slug) {
            return (
              <li className="relative flex items-center" key={index}>
                {slug === product.slug && (
                  <div className="bg-primary absolute -left-14 h-1 w-10"></div>
                )}
                <AniLink
                  fade
                  to={product.slug}
                  className={`hover:text-primary ${
                    slug === product.slug && "text-primary"
                  }`}
                >
                  {product.label}
                </AniLink>
              </li>
            );
          } else {
            return <li key={index}>{product.label}</li>;
          }
        })}
      </ul>

      <div className="my-10 border-t border-black/30"></div>

      <div className="heading-five">
        Ready to bring your existing design or new concept to life?
      </div>
      <ButtonSolid
        as="button"
        data="modal-contact"
        text="Get a Quote"
        className="w-full"
      />
    </div>
  );
};

export default ProductsSidebar;
