import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import ButtonSolid from "../Button/ButtonSolid";

const industries = [
  {
    slug: "/rv-component-manufacturer/",
    label: "RV Components",
  },
  {
    slug: "/mass-transit-parts-manufacturer/",
    label: "Mass Transit Companies",
  },
  {
    slug: "/themed-parts-manufacturer/",
    label: "Themed Products",
  },
  {
    slug: null,
    label: "Infrastructure",
  },
  {
    slug: "/fiberglass-product-manufacturer/",
    label: "Industrial Equipment",
  },
  {
    slug: "/fiberglass-medical-equipment-manufacturer/",
    label: "Medical Equipment",
  },
  {
    slug: "/marine-fiberglass-manufacturer/",
    label: "Marine",
  },
  {
    slug: null,
    label: "Automotive",
  },
  {
    slug: null,
    label: "Sporting Goods",
  },
  {
    slug: "/fiberglass-decor-manufacturer/",
    label: "Fiberglass Decor",
  },
  {
    slug: "/fiberglass-shelter-manufacturer/",
    label: "Fiberglass Shelters",
  },
];

const IndustriesWeServe = ({ className }) => {
  let slug = "";
  if (typeof window !== "undefined") {
    slug = window.location.pathname;
  }

  return (
    <div className="sticky top-40 bg-gray-50 px-10 py-10">
      <div className="heading-five">Industries</div>
      <ul className="space-y-2">
        {industries.map((industry, index) => {
          if (industry.slug) {
            return (
              <li className="relative flex items-center" key={index}>
                {slug === industry.slug && (
                  <div className="bg-primary absolute -left-14 h-1 w-10"></div>
                )}
                <AniLink
                  fade
                  to={industry.slug}
                  className={`hover:text-primary ${
                    slug === industry.slug && "text-primary"
                  }`}
                >
                  {industry.label}
                </AniLink>
              </li>
            );
          } else {
            return <li key={index}>{industry.label}</li>;
          }
        })}
      </ul>

      <div className="my-10 border-t border-black/30"></div>

      <div className="heading-five">
        Ready to bring your existing design or new concept to life?
      </div>
      <ButtonSolid
        as="button"
        data="modal-contact"
        text="Get a Quote"
        className="w-full"
      />
    </div>
  );
};

export default IndustriesWeServe;
