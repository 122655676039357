import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Services from "../components/Repeating/Services";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";
import IndustriesSidebar from "../components/Repeating/IndustriesSidebar";
import ButtonSolid from "../components/Button/ButtonSolid";
import ProductsSidebar from "../components/Repeating/ProductsSidebar";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout
      headerStyle="overlap"
      headerLinkColor="white"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="Fiberglass Trench Covers | Painted Rhino"
        description="Durable, lightweight fiberglass trench covers for industrial, commercial, and utility applications. Non-slip, corrosion-resistant, and easy to install. Shop now!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <div className="flex flex-col-reverse items-center gap-y-4 lg:grid lg:grid-cols-2 max-w-[1200px] w-full mx-auto pb-20 pt-10 lg:pt-[120px] px-4">
        <div className="flex items-center lg:items-start max-w-[561px] flex-col">
          <h1>Fiberglass Trench Covers</h1>
          {/* <p className="font-semibold text-3xl leading-[30.8px] text-[#444] mb-5 -mt-2">
          Prices starting at $6,500
          </p> */}
          <p>
            When it comes to construction sites, open trenches are a serious
            safety hazard. Unfortunately, traditional covers warp, crack, and
            rot, putting projects and workers at risk. Painted Rhino’s
            fiberglass trench covers offer a superior solution that’s safer,
            lighter, more durable, and more cost-efficient. It’s time to choose
            a fiberglass trench cover that works as hard as you do.
          </p>
          <ButtonSolid
            as="button"
            data="modal-contact"
            text="Get a Free Quote"
            className="w-full md:w-auto"
          />
        </div>
        <img
          src={data.heroImage.publicURL}
          alt=""
          width={200}
          className="w-full object-contain px-4"
        />
      </div>

      <section className="pb-18 md:pb-36">
        <div className="container">
          <div className="grid gap-y-12 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-12 md:mb-16">
                <h2>What Are Fiberglass Trench Covers?</h2>
                <p>
                  Fiberglass trench covers are lightweight yet sturdy panels
                  that cover open trenches used to house essential utilities
                  like electrical wiring, plumbing, and drainage systems.
                </p>
                <p>
                  Trench covers not only prevent falls and injuries but also
                  protect equipment from debris, weather exposure, and equipment
                  damage. The trouble is traditional covers made from wood,
                  concrete, and steel are not only inefficient but pose serious
                  safety hazards.
                </p>
                <p>
                  Wood rots. Concrete is heavy and prone to cracking. Steel is
                  expensive to manufacture and warps, rusts, and corrodes. Our
                  fiberglass trench covers are a superior alternative that:
                </p>
                <ul className="styled-list">
                  <li>Improve job site safety</li>
                  <li>Resist corrosion, rot, and weather damage</li>
                  <li>Support heavy loads</li>
                  <li>Require minimal effort to install and remove</li>
                  <li>Are more cost-efficient to manufacture</li>
                </ul>
              </div>

              <h2>Key Features of Fiberglass Trench Covers</h2>
              <p>
                The construction industry is increasingly turning to fiberglass
                trench covers due to their exceptional performance and
                durability. Here’s what sets them apart:
              </p>
              <h3 className="heading-five">Strength</h3>
              <p>
                Fiberglass trench covers maintain flexibility without
                compromising strength. This allows them to absorb impact without
                cracking, making them ideal for high-traffic areas and
                heavy-duty applications. With a high strength-to-weight ratio,
                fiberglass trench covers are quickly becoming the industry
                standard.
              </p>
              <h3 className="heading-five">Lightweight Design</h3>
              <p>
                Lightweight trench covers are just as easy to transport as they
                are to install. Reduced weight means fewer labor requirements
                and reduced risk of injury compared to concrete and steel
                alternatives.
              </p>
              <h3 className="heading-five">Fire-Resistant</h3>
              <p>
                Fiberglass trench covers are engineered with fire-retardant
                properties, allowing them to withstand high temperatures and
                prevent the spread of flames. This makes them an ideal choice
                for industrial applications where fire safety is a concern.
              </p>
              <h3 className="heading-five">Corrosion-Resistant </h3>
              <p>
                Unlike traditional materials like wood, concrete, and steel,
                fiberglass trench covers are corrosion-resistant. They won’t
                rot, crack, or warp and are well-suited for extreme environments
                where chemicals, UV rays, and extreme temperatures are a
                concern.
              </p>

              <h2>Common Fiberglass Trench Cover Applications </h2>
              <p>
                Fiberglass trench covers are becoming the preferred solution
                across multiple industries. Here’s where they excel:
              </p>
              <h3 className="heading-five">Industrial Settings</h3>
              <p>
                Our trench covers see extensive use in manufacturing facilities.
                In addition to protecting electrical wiring, pipes, and
                plumbing, they allow for easy maintenance access. When you need
                to maintain safety in a high-impact environment, our fiberglass
                covers provide superior protection against heavy equipment,
                chemical exposure, and constant foot traffic.
              </p>
              <h3 className="heading-five">Public Infrastructure</h3>
              <p>
                Municipal water and sewage systems demand reliable solutions
                that can withstand constant exposure to extreme elements. Our
                fiberglass trench covers meet public infrastructure requirements
                while reducing replacement costs.
              </p>
              <h3 className="heading-five">Underground Utilities</h3>
              <p>
                Fiberglass trench covers also protect the essential
                infrastructure that keeps our cities running. Underground
                electrical and telecommunication lines, gas pipes, and fiber
                optic networks require regular maintenance and security. Our
                fiberglass trench covers protect sensitive infrastructure while
                minimizing disruption.
              </p>
              <h3 className="heading-five">Transit Systems</h3>
              <p>
                Transit systems need trench covers that can withstand heavy
                pedestrian and vehicle traffic. Fiberglass trench covers are
                ideal for subway stations, light rail systems, and bus
                terminals—environments that demand exceptional performance and
                durability.
              </p>
            </div>
            <div className="md:col-end-13 lg:col-span-4">
              <ProductsSidebar />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pb-20 md:pb-32">
        <div className="container">
          <div className="grid gap-2 md:grid-cols-2 md:gap-4">
            <div className="grid grid-cols-10 grid-rows-2 gap-2 md:gap-4">
              <div className="col-span-6 col-start-1 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(0)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail1.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-span-4 col-end-11 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(1)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail2.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-span-4 col-start-1 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(4)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail5.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-span-6 col-end-11 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(3)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail4.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
            </div>
            <div className="grid grid-flow-col grid-cols-10 grid-rows-2 gap-2 md:gap-4">
              <div className="col-span-4 row-span-2">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(2)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail3.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-span-6 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(5)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail6.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
              <div className="col-span-6 row-span-1">
                <button
                  aria-label="Modal trigger"
                  data-modal-open="modal-gallery"
                  onClick={() => setSlideIndex(6)}
                >
                  <Img
                    fadeIn={false}
                    loading="eager"
                    className="h-full w-full"
                    fluid={data.galleryThumbnail7.childImageSharp.fluid}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <WhyUs
        className="pb-20 md:pb-28"
        contentAlignment="md:text-center md:mx-auto"
        headerHeading="The Painted Rhino Difference."
      /> */}

      {/* <TestimonialBackgroundImage /> */}

      {/* <Services
        className="mb-22 pt-20 md:mb-32 md:pt-32"
        headerHeading="Our Manufacturing Processes"
        headerText="At Painted Rhino, you bring us your design and we’ll manufacture it. We’re experts in:"
      /> */}

      <CTABackgroundImage
        ctaHeading="We Cover Your Most Valuable Assets"
        ctaSubText="Ready to talk trench solutions? Reach out today to learn more about pricing and custom solutions designed to your exact specifications."
      />

      {/* <ModalGallery slideIndex={slideIndex}>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox1.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox2.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox3.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox4.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox5.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox6.childImageSharp.fluid}
            alt=""
          />
        </div>
        <div>
          <Img
            fadeIn={false}
            loading="eager"
            fluid={data.galleryLightbox7.childImageSharp.fluid}
            alt=""
          />
        </div>
      </ModalGallery> */}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  {
    heroDesktop: file(
      relativePath: { eq: "products/fiberglass-trench-covers.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImage: file(
      relativePath: { eq: "products/fiberglass-trench-covers.jpg" }
    ) {
      publicURL
    }
    galleryThumbnail1: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 355) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox1: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail2: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 221) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox2: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail3: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 222) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox3: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail4: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 354) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox4: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail5: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 222) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox5: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail6: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 354) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox6: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryThumbnail7: file(
      relativePath: { eq: "industries/Shelters/Gallery-Thumbnails/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 354) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryLightbox7: file(
      relativePath: { eq: "industries/Shelters/Gallery-Lightbox/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
